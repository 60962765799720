import CompleteMultiContentTier from '@fragment/shared/CompleteMultiContentTier';
import CompleteSplitScreenTier from '@fragment/shared/CompleteSplitScreenTier';
import CompleteGlobalTier from '@fragment/shared/CompleteGlobalTier';
import gql from 'graphql-tag';

export default gql`
    fragment MultiTiers on StreamFieldInterface {
        ...CompleteMultiContentTier
        ...CompleteSplitScreenTier
        ...CompleteGlobalTier
    }
    ${CompleteMultiContentTier}
    ${CompleteSplitScreenTier}
    ${CompleteGlobalTier}
`;
