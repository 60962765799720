import gql from 'graphql-tag'

export default gql`
    fragment CompleteExternalArticleBlock on ExternalArticleBlock {
        blockType
        id
        name
        externalLink: link
        source
        ogImage
    }
`;
