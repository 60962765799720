import CompleteCurriculumLessonAgendaBlock from '@fragment/shared/CompleteCurriculumLessonAgendaBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumLessonAgendaTier on CurriculumLessonAgendaTier {
        header
        content {
            ...CompleteCurriculumLessonAgendaBlock
        }
    }
    ${CompleteCurriculumLessonAgendaBlock}
`;
