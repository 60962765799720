import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import PagePreview from '@fragment/previews/PagePreview';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteCustomForm from '@fragment/pages/CompleteCustomForm';
import CompletePressKitContact from '@fragment/shared/CompletePressKitContact';
import gql from 'graphql-tag';

export default gql`
    fragment CompletePressReleaseBlogLanding on PressReleaseBlogLanding {
        id
        noIndex
        access
        title
        showFeaturedPost
        featuredPost {
            ...CompletePageChooserBlock
        }
        latestPost {
            ...PagePreview
        }
        pressListForm {
            ...CompleteCustomForm
        }
        pressKitContact {
            ...CompletePressKitContact
        }
        searchQuery
        searchQueryFragment
        searchQueryOptions {
            __typename
            name
            label
            options {
                __typename
                id: filterId
                name: filterName
                field: filterField
            }
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompletePageChooserBlock}
    ${PagePreview}
    ${CompleteCustomImage}
    ${CompleteCustomForm}
    ${CompletePressKitContact}
`;
