import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumVideoSectionTier on CurriculumVideoSectionTier {
        header
        videoTitle
        videoThumbnail {
            ...CompleteCustomImage
        }
        video {
            ...CompleteVideoBlock
        }
    }
    ${CompleteCustomImage}
    ${CompleteVideoBlock}
`;
