import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteGlobalTier from '@fragment/shared/CompleteGlobalTier';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';
import CompleteOneColumnTier from '@fragment/shared/CompleteOneColumnTier';
import PagePreview from '@fragment/previews/PagePreview';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteMarketingEventsLanding on MarketingEventsLanding {
        id
        noIndex
        access
        title
        featuredContent {
            ...CompletePageChooserBlock
        }
        content {
            ...CompleteGlobalTier
            ...CompleteRelatedTier
            ...CompleteOneColumnTier
        }
        upcomingEvents {
            ...PagePreview
        }
        pastEvents {
            ...PagePreview
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompletePageChooserBlock}
    ${CompleteGlobalTier}
    ${CompleteRelatedTier}
    ${CompleteOneColumnTier}
    ${PagePreview}
    ${CompleteCustomImage}
`;
