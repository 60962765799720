import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteBenchmarkAssessmentPage on BenchmarkAssessmentPage {
        title
        url
        component
        edition
        language
        docType
        breadcrumb
        sections
        downloadables {
            ...CompleteDownloadables
        }
    }

    ${CompleteDownloadables}
`;
