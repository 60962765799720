import CompleteCurriculumRecommendedTextObjectType from '@fragment/shared/CompleteCurriculumRecommendedTextObjectType';
import CompleteDocumentBlock from '@fragment/shared/CompleteDocumentBlock';
import CompleteCurriculumPageGradeBlock from '@fragment/shared/CompleteCurriculumPageGradeBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumPlanningLandingRecommendedTextPage on CurriculumPlanningLandingRecommendedTextPage {
        id
        title
        allRecommendedTexts {
            ...CompleteCurriculumRecommendedTextObjectType
        }
        documentList {
            ...CompleteDocumentBlock
        }
        pageGrades {
            ...CompleteCurriculumPageGradeBlock
        }
    }
    ${CompleteCurriculumRecommendedTextObjectType}
    ${CompleteDocumentBlock}
    ${CompleteCurriculumPageGradeBlock}
`;
