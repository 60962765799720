import CompleteCurriculumMaterialChooserBlock from '@fragment/shared/CompleteCurriculumMaterialChooserBlock';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteWebsiteBlock from '@fragment/shared/CompleteWebsiteBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumMaterialTier on CurriculumMaterialTier {
        header
        materials {
            ...CompleteCurriculumMaterialChooserBlock
            ...CompletePageChooserBlock
            ...CompleteWebsiteBlock
        }
    }
    ${CompleteCurriculumMaterialChooserBlock}
    ${CompletePageChooserBlock}
    ${CompleteWebsiteBlock}
`;
