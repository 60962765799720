import PagePreview from '@fragment/previews/PagePreview';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompletePerson from '@fragment/pages/CompletePerson';
import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteExternalArticleBlock from '@fragment/shared/CompleteExternalArticleBlock';
import CompleteQuoteBlock from '@fragment/shared/CompleteQuoteBlock';
import CompleteExcerptBlock from '@fragment/shared/CompleteExcerptBlock';
import CompleteWebsiteBlock from '@fragment/shared/CompleteWebsiteBlock';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteInTheNewsBlogPost on InTheNewsBlogPost {
        id
        noIndex
        access
        title
        url
        subtitle
        firstPublishedAt
        lastPublishedAt
        publishedDateOverride
        previousPost {
            ...PagePreview
        }
        nextPost {
            ...PagePreview
        }
        thumbnailImage {
            ...CompleteCustomImage
        }
        relatedPosts {
            ...CompletePageChooserBlock
        }
        categories {
            id
            name
        }
        sourceTitle
        sourceLink
        sourceLogo {
            ...CompleteCustomImage
        }
        author {
            id
            ...CompletePerson
        }
        relatedResources {
            ...CompletePageChooserBlock
        }
        corePractices {
            ...PagePreview
        }
        content {
            ...CompleteRichTextContentBlock
            ...CompleteExternalArticleBlock
            ...CompleteQuoteBlock
            ...CompleteExcerptBlock
            ...CompleteWebsiteBlock
            ...CompleteVideoBlock
        }
        footerContent {
            ...DefaultTiers
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${PagePreview}
    ${CompleteCustomImage}
    ${CompletePageChooserBlock}
    ${CompletePerson}
    ${CompleteRichTextContentBlock}
    ${CompleteExternalArticleBlock}
    ${CompleteQuoteBlock}
    ${CompleteExcerptBlock}
    ${CompleteWebsiteBlock}
    ${CompleteVideoBlock}
    ${DefaultTiers}
`;
