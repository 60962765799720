import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteTierGroup from '@fragment/shared/CompleteTierGroup';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteTermsOfUsePage on TermsOfUsePage {
        id
        noIndex
        access
        versionNumber
        hero {
            ...CompleteHeroTier
        }
        content {
            ...DefaultTiers
            ...CompleteTierGroup
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteHeroTier}
    ${DefaultTiers}
    ${CompleteTierGroup}
    ${CompleteCustomImage}
`;
