import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumStandardContentType on CurriculumStandardContentType {
        externalId
        standardType
        grade
        description
    }
`;
