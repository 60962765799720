import CompleteSimpleTableBlock from '@fragment/shared/CompleteSimpleTableBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumSimpleTableTier on CurriculumSimpleTableTier {
        blockType
        content {
            ...CompleteSimpleTableBlock
        }
    }
    ${CompleteSimpleTableBlock}
`;
