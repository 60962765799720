import gql from 'graphql-tag'

export default gql`
    fragment ProfessionalLearningPreview on PLCatalogEntry {
        id
        title
        description
        url
        type
        topics {
            name
        }
        upcomingPlEventsCount
    }
`;
