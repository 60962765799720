import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumStandardObjectType on CurriculumStandardObjectType {
        externalId
        grade
        module
        standardType
        description
        isFocusedStandard
        isSupportingStandard
        notes
    }
`;
