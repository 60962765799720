import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteCTABlock from '@fragment/shared/CompleteCTABlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumTwoColumnAndImageTier on CurriculumTwoColumnAndImageTier {
        headline
        highlight
        description
        blockStyle
        image {
            ...CompleteCustomImage
        }
        cta {
            ...CompleteCTABlock
        }
    }
    ${CompleteCustomImage}
    ${CompleteCTABlock}
`;
