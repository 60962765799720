import CompleteOneColumnTier from '@fragment/shared/CompleteOneColumnTier';
import CompleteTwoColumnTier from '@fragment/shared/CompleteTwoColumnTier';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteDonationPage on DonationPage {
        id
        noIndex
        access
        title
        additionalInfo
        description
        content {
            ...CompleteOneColumnTier
            ...CompleteTwoColumnTier
        }
        embedScript
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteOneColumnTier}
    ${CompleteTwoColumnTier}
    ${CompleteCustomImage}
`;
