<template>
    <div
        v-if="showGuard"
        class="content-guard-wrapper"
        :class="{ '--halfway-guard': isHalfwayContentGuard && showGuard, active: scrollHalfway }"
    >
        <div class="content-guard">
            <div class="content-guard__block">
                <!-- premium guard -->
                <template v-if="requiresPremiumAccess">
                    <div class="content-guard__top-bar">
                        <div class="content-wrapper">
                            <div class="big-body --bold">This content is for premium subscribers and EL partners.</div>
                        </div>
                    </div>
                    <div class="content-guard__body">
                        <div class="content-wrapper">
                            <div class="content-guard__upsell-message">
                                <h1 class="header-1">Become a Premium Member</h1>
                                <div class="body">
                                    EL Partners enjoy access to hundreds of premium resources through their
                                    organizations. Individuals can gain access by subscribing to a monthly paid
                                    membership.
                                </div>
                                <div class="content-guard__ctas">
                                    <a
                                        v-if="premiumGuard.primaryCTA.action"
                                        class="button --crimson"
                                        @click="premiumGuard.primaryCTA.action()"
                                        >{{ premiumGuard.primaryCTA.label }}</a
                                    >
                                    <a
                                        v-else-if="premiumGuard.primaryCTA.link"
                                        class="button --crimson"
                                        :href="premiumGuard.primaryCTA.link"
                                        >{{ premiumGuard.primaryCTA.label }}</a
                                    >
                                    <a
                                        v-if="premiumGuard.secondaryCTA"
                                        @click="premiumGuard.secondaryCTA.action()"
                                        class="button"
                                        >{{ premiumGuard.secondaryCTA.label }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <!-- default login guard -->
                <template v-if="isGuestUser && !requiresPremiumAccess">
                    <div class="content-guard__top-bar">
                        <div class="content-wrapper">
                            <div class="big-body --bold">Please sign in to access EL resources.</div>
                            <div v-if="isHalfwayContentGuard" @click="closeContentGuard" class="content-guard__close">
                                <Icon icon="close" />
                            </div>
                        </div>
                    </div>
                    <div class="content-guard__body">
                        <div class="content-wrapper">
                            <div class="content-guard__upsell-message">
                                <h1 class="header-1">Sign in or Create an Account</h1>
                                <div class="body">
                                    Creating an account gives you access to hundreds of free resources.
                                </div>
                                <div class="content-guard__ctas">
                                    <a
                                        v-if="contentGuard.secondaryCTA"
                                        @click="contentGuard.secondaryCTA.action()"
                                        class="button --crimson"
                                        >{{ contentGuard.secondaryCTA.label }}</a
                                    >
                                    <a
                                        v-if="contentGuard.primaryCTA.link"
                                        class="button --outline"
                                        :href="contentGuard.primaryCTA.link"
                                        >{{ contentGuard.primaryCTA.label }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@module/Icon';

export default {
    components: {
        Icon,
    },
    props: {
        access: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            scrollHalfway: false,
            contentGuardHeight: 0,
            contentGuardClosed: false,
        };
    },

    watch: {
        scrollHalfway: {
            handler(newVal, oldVal) {
                if (process.client) {
                    if (newVal !== oldVal) {
                        if (newVal) {
                            document.documentElement.style.overflow = 'hidden';
                            this.contentGuardClosed = false;
                        } else {
                            document.documentElement.style.overflow = 'auto';
                        }
                    }
                } else {
                    document.documentElement.style.overflow = 'auto';
                }
            },
        },
        contentGuardClosed: {
            handler(newVal, oldVal) {
                if (process.client) {
                    if (newVal !== oldVal) {
                        if (newVal) {
                            this.scrollHalfway = false;
                            window.scrollTo(0, this.scrollPoint - this.contentGuardHeight);
                        }
                    }
                }
            },
        },
        access: {
            handler(newVal, oldVal) {
                if (process.client) {
                    if (newVal !== oldVal && newVal === 'PUBLIC') {
                        document.body.style.overflow = 'visible';
                        document.body.style.height = 'auto';
                    }
                }
            },
        },
        showGuard: {
            handler(newVal, oldVal) {
                if (process.client) {
                    if (newVal !== oldVal && newVal === false) {
                        document.body.style.overflow = 'visible';
                        document.body.style.height = 'auto';
                    }
                }
            },
        },
    },

    methods: {
        scrollHandler() {
            if (document.documentElement.scrollTop > this.scrollPoint) {
                this.scrollHalfway = true;
            } else {
                this.scrollHalfway = false;
            }
        },
        closeContentGuard() {
            this.contentGuardClosed = true;
        },
    },

    computed: {
        isGuestUser() {
            const { currentUser = { isGuest: true } } = this;
            return currentUser.isGuest;
        },
        hasPreview() {
            return this.access === 'AUTHED_PREVIEW' && this.isGuestUser;
        },
        showGuard() {
            return (
                (this.access === 'AUTHED' && this.isGuestUser) ||
                (this.access === 'AUTHED_PREVIEW' && this.isGuestUser) ||
                (this.access === 'PREMIUM' && !this.userHasPremiumAccess)
            );
        },
        scrollPoint() {
            return document.documentElement.scrollHeight / 4;
        },
        isHalfwayContentGuard() {
            return this.hasPreview;
        },
        requiresPremiumAccess() {
            return this.access === 'PREMIUM' && !this.userHasPremiumAccess;
        },

        contentGuard() {
            const { currentUser = { isGuest: true } } = this;
            return {
                primaryCTA: {
                    label: currentUser.isGuest ? 'Create An Account' : null,
                    link: currentUser.isGuest ? '/create-an-account' : null,
                    action: currentUser.isGuest ? null : () => this.upgradeAccount({ fromPage: this.$route.path }),
                },
                secondaryCTA: currentUser.isGuest
                    ? {
                          label: 'Log In',
                          action: () => this.$auth.login({ next: this.$route.path }),
                      }
                    : null,
            };
        },

        premiumGuard() {
            const { currentUser = { isGuest: true } } = this;
            return {
                primaryCTA: {
                    label: currentUser.isGuest ? 'Create An Account' : 'Upgrade Your Account',
                    link: currentUser.isGuest ? '/create-an-account' : null,
                    action: currentUser.isGuest ? null : () => this.upgradeAccount({ fromPage: this.$route.path }),
                },
                secondaryCTA: currentUser.isGuest
                    ? {
                          label: 'Sign In To Your Account',
                          action: () => this.$auth.login({ next: this.$route.path }),
                      }
                    : null,
            };
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.isHalfwayContentGuard === true) {
                document.addEventListener('scroll', this.scrollHandler);
            }
            this.contentGuardHeight = document?.getElementsByClassName('content-guard__block')[0]?.clientHeight;
        });

        if (this.showGuard && !this.isHalfwayContentGuard) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100%';
            this.contentGuardClosed = false;
        } else {
            document.body.style.overflow = 'visible';
            document.body.style.height = 'auto';
        }
    },
    beforeDestroy() {
        document.removeEventListener('scroll', this.scrollHandler);
    },
};
</script>
