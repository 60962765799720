import gql from 'graphql-tag';

export default gql`
    fragment CompleteSearchOptions on FilterOptions {
        name
        label
        options {
            __typename
            id: filterId
            name: filterName
            field: filterField
        }
        widget
        startOpen
    }
`;
