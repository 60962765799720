import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteColumnTierColumn from '@fragment/shared/CompleteColumnTierColumn';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteTwoColumnBaseTier on TwoColumnBaseTier {
        id
        blockStyle
        blockType
        header
        dek
        backgroundImage {
            ...CompleteCustomImage
        }
        displayType
        paddingStyle
        rightColumn {
            ...CompleteColumnTierColumn
        }
        leftColumn {
            ...CompleteColumnTierColumn
        }
        linkingIcon {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
    ${CompleteColumnTierColumn}
`;
