import CompletePressKitContact from '@fragment/shared/CompletePressKitContact';
import CompleteDocumentBlock from '@fragment/shared/CompleteDocumentBlock';
import CompleteExternalArticleBlock from '@fragment/shared/CompleteExternalArticleBlock';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompletePressKit on PressKit {
        contact {
            ...CompletePressKitContact
        }
        files {
            ...CompleteDocumentBlock
        }
        articles {
            ...CompleteExternalArticleBlock
        }
        blogPosts {
            ...CompletePageChooserBlock
        }
    }
    ${CompletePressKitContact}
    ${CompleteDocumentBlock}
    ${CompleteExternalArticleBlock}
    ${CompletePageChooserBlock}
`;
