import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumRecommendedTextObjectType on CurriculumRecommendedTextObjectType {
        grade
        module
        title
        author
        language
        publisher
        isbnUpc
        textType
        lexile
        year
        medium
    }
`;
