import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteAwardRecipient on AwardRecipients {
        person {
            ... on Person {
                fullName
                photo {
                    ...CompleteCustomImage
                }
                schoolName
                url
                urlPath
            }
        }
        featured
        featuredDescription
        year
    }
    ${CompleteCustomImage}
`;
