import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompletePricingPlanBlock from '@fragment/shared/CompletePricingPlanBlock';
import CompleteFloatingImageBlock from '@fragment/shared/CompleteFloatingImageBlock';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteDistrictPartnershipsPage on DistrictPartnershipsPage {
        id
        noIndex
        access
        hero {
            ...CompleteHeroTier
        }
        content {
            ...DefaultTiers
        }
        pricingPlans {
            ...CompletePricingPlanBlock
        }
        pricingPlanHeader
        pricingPlanFloatingImages {
            ...CompleteFloatingImageBlock
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteHeroTier}
    ${DefaultTiers}
    ${CompletePricingPlanBlock}
    ${CompleteFloatingImageBlock}
    ${CompleteCustomImage}
`;
