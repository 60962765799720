import CompleteTwoColumnBaseTier from '@fragment/shared/CompleteTwoColumnBaseTier';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteSplitScreenTier on SplitScreenTier {
        blockType
        splitscreen {
            ...CompleteTwoColumnBaseTier
        }
        header
        description
    }
    ${CompleteTwoColumnBaseTier}
`;
