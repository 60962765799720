import CompleteCurriculumComponentBlock from '@fragment/shared/CompleteCurriculumComponentBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumComponentsBlock on CurriculumComponentsBlock {
        components {
            ...CompleteCurriculumComponentBlock
        }
        description
    }
    ${CompleteCurriculumComponentBlock}
`;
