import CompleteOneColumnTier from '@fragment/shared/CompleteOneColumnTier';
import CompleteTwoColumnBaseTier from '@fragment/shared/CompleteTwoColumnBaseTier';
import CompleteCurriculumTwoColumnAndImageTier from '@fragment/shared/CompleteCurriculumTwoColumnAndImageTier';
import CompleteCurriculumArrowTier from '@fragment/shared/CompleteCurriculumArrowTier';
import CompleteMediaTier from '@fragment/shared/CompleteMediaTier';
import CompleteTableBlock from '@fragment/shared/CompleteTableBlock';
import CompleteAccordionBlock from '@fragment/shared/CompleteAccordionBlock';
import CompleteNumberTileTier from '@fragment/shared/CompleteNumberTileTier';
import CompleteTileTier from '@fragment/shared/CompleteTileTier';
import CompleteFormTier from '@fragment/shared/CompleteFormTier';
import CompleteGalleryTier from '@fragment/shared/CompleteGalleryTier';
import CompleteFeaturedResourceTier from '@fragment/shared/CompleteFeaturedResourceTier';
import CompleteContentCardsTier from '@fragment/shared/CompleteContentCardsTier';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';
import CompleteLinkSetTier from '@fragment/shared/CompleteLinkSetTier';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteTierGroup on TierGroup {
        showInSubnavigation
        subnavigationLabel
        subnavigationId
        backgroundColor
        header
        headerHighlight
        blocks {
            ...CompleteOneColumnTier
            ...CompleteTwoColumnBaseTier
            ...CompleteCurriculumTwoColumnAndImageTier
            ...CompleteCurriculumArrowTier
            ...CompleteMediaTier
            ...CompleteTableBlock
            ...CompleteAccordionBlock
            ...CompleteNumberTileTier
            ...CompleteTileTier
            ...CompleteFormTier
            ...CompleteGalleryTier
            ...CompleteFeaturedResourceTier
            ...CompleteContentCardsTier
            ...CompleteRelatedTier
            ...CompleteLinkSetTier
        }
    }
    ${CompleteOneColumnTier}
    ${CompleteTwoColumnBaseTier}
    ${CompleteCurriculumTwoColumnAndImageTier}
    ${CompleteCurriculumArrowTier}
    ${CompleteMediaTier}
    ${CompleteTableBlock}
    ${CompleteAccordionBlock}
    ${CompleteNumberTileTier}
    ${CompleteTileTier}
    ${CompleteFormTier}
    ${CompleteGalleryTier}
    ${CompleteFeaturedResourceTier}
    ${CompleteContentCardsTier}
    ${CompleteRelatedTier}
    ${CompleteLinkSetTier}
`;
