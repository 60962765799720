import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';
import CompleteCurriculumLink from '@fragment/shared/CompleteCurriculumLink';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteWholeGroupLessonPage on WholeGroupLessonPage {
        access
        url
        component
        language
        edition
        grade
        microphase
        cycle
        lesson
        language
        eyebrow
        title
        subtitle
        summary
        breadcrumb
        chapters
        curriculumMap
        downloadables {
            ...CompleteDownloadables
        }
        previous {
            ...CompleteCurriculumLink
        }
        next {
            ...CompleteCurriculumLink
        }
        related {
            ...CompleteRelatedTier
        }
    }

    ${CompleteDownloadables}
    ${CompleteCurriculumLink}
    ${CompleteRelatedTier}
`;
