import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompleteTierGroup from '@fragment/shared/CompleteTierGroup';
import CompleteCurriculumTwoColumnAndImageTier from '@fragment/shared/CompleteCurriculumTwoColumnAndImageTier';
import CompleteCurriculumArrowTier from '@fragment/shared/CompleteCurriculumArrowTier';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteTableBlock from '@fragment/shared/CompleteTableBlock';
import CompleteAccordionBlock from '@fragment/shared/CompleteAccordionBlock';
import CompletePeopleBlock from '@fragment/shared/CompletePeopleBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteInformational on Informational {
        id
        noIndex
        access
        title
        hero {
            ...CompleteHeroTier
        }
        content {
            ...DefaultTiers
            ...CompleteTierGroup
            ...CompleteCurriculumTwoColumnAndImageTier
            ...CompleteCurriculumArrowTier
            ...CompleteTableBlock
            ...CompleteAccordionBlock
            ...CompletePeopleBlock
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteHeroTier}
    ${DefaultTiers}
    ${CompleteTierGroup}
    ${CompleteCurriculumTwoColumnAndImageTier}
    ${CompleteCurriculumArrowTier}
    ${CompleteCustomImage}
    ${CompleteTableBlock}
    ${CompleteAccordionBlock}
    ${CompletePeopleBlock}
`;
