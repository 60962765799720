import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCareersLandingPage on CareersLandingPage {
        id
        title
        hero {
            ...CompleteHeroTier
        }
        content {
            ...DefaultTiers
        }
        jobOpeningPageUrls
    }
    ${CompleteHeroTier}
    ${DefaultTiers}
`;
