import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteSmallGroupMicrophasePage on SmallGroupMicrophasePage {
        access
        url
        component
        edition
        microphase
        breadcrumb
        title
        eyebrow
        introduction
        chapters
        curriculumMap
        downloadables {
            ...CompleteDownloadables
        }
        related {
            ...CompleteRelatedTier
        }
    }

    ${CompleteDownloadables}
    ${CompleteRelatedTier}
`;
