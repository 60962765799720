import CompleteMarketingPhysicalLocationBlock from '@fragment/shared/CompleteMarketingPhysicalLocationBlock';
import CompleteMarketingEventVirtualLocationBlock from '@fragment/shared/CompleteMarketingEventVirtualLocationBlock';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';
import CompleteMarketingEventSpeakerBlock from '@fragment/shared/CompleteMarketingEventSpeakerBlock';
import CompletePressKit from '@fragment/shared/CompletePressKit';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompleteSocialMediaTier from '@fragment/shared/CompleteSocialMediaTier';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteMarketingEvent on MarketingEvent {
        noIndex
        access
        title
        type
        subtitle
        description
        startDate
        endDate
        timezone
        startTime
        endTime
        hasPassed
        downloadableDocuments
        eventLocation {
            ...CompleteMarketingPhysicalLocationBlock
            ...CompleteMarketingEventVirtualLocationBlock
        }
        thumbnailImage {
            ...CompleteCustomImage
        }
        hero {
            ...CompleteHeroTier
        }
        blogPosts {
            ...CompletePageChooserBlock
        }
        relatedEvents {
            ...CompleteRelatedTier
        }
        speakers {
            ...CompleteMarketingEventSpeakerBlock
        }
        pressKit {
            ...CompletePressKit
        }
        additionalContent {
            ...DefaultTiers
        }
        socialMedia {
            ...CompleteSocialMediaTier
        }
        featuredVideo {
            ...CompleteVideoBlock
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteMarketingPhysicalLocationBlock}
    ${CompleteMarketingEventVirtualLocationBlock}
    ${CompleteCustomImage}
    ${CompleteHeroTier}
    ${CompletePageChooserBlock}
    ${CompleteRelatedTier}
    ${CompleteMarketingEventSpeakerBlock}
    ${CompletePressKit}
    ${DefaultTiers}
    ${CompleteSocialMediaTier}
    ${CompleteVideoBlock}
`;
