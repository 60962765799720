import CompleteCurriculumComponentsCategoryBlock from '@fragment/shared/CompleteCurriculumComponentsCategoryBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumComponentsTier on CurriculumComponentsTier {
        headline
        highlight
        description
        blockStyle
        categories {
            ...CompleteCurriculumComponentsCategoryBlock
        }
    }
    ${CompleteCurriculumComponentsCategoryBlock}
`;
