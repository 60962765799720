import CompleteCustomImageObjectType from '@fragment/shared/CompleteCustomImageObjectType';
import CompleteTaxonomyRecord from '@fragment/shared/CompleteTaxonomyRecord';
import gql from 'graphql-tag';

export default gql`
    fragment CompletePeople on People {
        peopleGroups {
            ... on PeopleTierGroup {
                showInSubnavigation
                subnavigationLabel
                subnavigationId
                backgroundColor
                peopleByType {
                    name
                    description
                    linkToProfile
                    people {
                        hasBioPage
                        showInquireButton
                        firstName
                        lastName
                        grade
                        photo {
                            ...CompleteCustomImageObjectType
                        }
                        schoolName
                        department {
                            ...CompleteTaxonomyRecord
                        }
                        titles
                        url
                        boardTitle
                    }
                }
            }
        }
    }
    ${CompleteCustomImageObjectType}
    ${CompleteTaxonomyRecord}
`;
