import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteExcerptBlock on ExcerptBlock {
        id
        blockType
        extract
        origin {
        ...CompleteLinkBlock
        }
        author
    }
    ${CompleteLinkBlock}
`;
