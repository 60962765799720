import CompleteCurriculumRequiredTextObjectType from '@fragment/shared/CompleteCurriculumRequiredTextObjectType';
import CompleteDocumentBlock from '@fragment/shared/CompleteDocumentBlock';
import CompleteCurriculumPageGradeBlock from '@fragment/shared/CompleteCurriculumPageGradeBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumPlanningLandingRequiredTextPage on CurriculumPlanningLandingRequiredTextPage {
        id
        title
        allRequiredTexts {
            ...CompleteCurriculumRequiredTextObjectType
        }
        documentList {
            ...CompleteDocumentBlock
        }
        pageGrades {
            ...CompleteCurriculumPageGradeBlock
        }
    }
    ${CompleteCurriculumRequiredTextObjectType}
    ${CompleteDocumentBlock}
    ${CompleteCurriculumPageGradeBlock}
`;
