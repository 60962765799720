import CompleteCurriculumLessonAgendaTier from '@fragment/shared/CompleteCurriculumLessonAgendaTier';
import CompleteCurriculumMaterialTier from '@fragment/shared/CompleteCurriculumMaterialTier';
import CompleteCurriculumLessonPlanTier from '@fragment/shared/CompleteCurriculumLessonPlanTier';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';
import CompleteCurriculumStandardContentTypeChooser from '@fragment/shared/CompleteCurriculumStandardContentTypeChooser';
import CompleteCurriculumLessonPageCurriculumAssessment from '@fragment/shared/CompleteCurriculumLessonPageCurriculumAssessment';
import CompleteCurriculumLessonPageCurriculumProtocolPage from '@fragment/pages/CompleteCurriculumLessonPageCurriculumProtocolPage';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';
import CompleteCurriculumLink from '@fragment/shared/CompleteCurriculumLink';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumLessonPage on CurriculumLessonPage {
        id
        noIndex
        access
        parent {
            id
            title
            url
            urlPath
            pageType
            parent {
                id
                title
                url
                urlPath
                pageType
                parent {
                    id
                    title
                    url
                    urlPath
                    pageType
                    parent {
                        id
                        title
                        url
                        urlPath
                        pageType
                    }
                }
            }
        }
        title
        formattedTitle
        subtitle
        componentType
        gradePage {
            id
            title
            url
        }
        moduleNumber
        unitNumber
        lessonNumber
        summary
        duration
        cssStandards
        dailyLearningTarget
        ongoingAssessments
        agenda {
            ...CompleteCurriculumLessonAgendaTier
        }
        teachingNotes
        opportunities
        howItBuilds
        supportAllStudents
        assessmentGuidance
        downTheRoad
        inAdvance
        technologyMultimedia
        vocabulary
        requiredTextsContent
        materialsFromPreviousLessons {
            ...CompleteCurriculumMaterialTier
        }
        newMaterials {
            ...CompleteCurriculumMaterialTier
        }
        classroomMaterials
        opening {
            ...CompleteCurriculumLessonPlanTier
        }
        workTime {
            ...CompleteCurriculumLessonPlanTier
        }
        closingAndAssessments {
            ...CompleteCurriculumLessonPlanTier
        }
        homework
        related {
            ...CompleteRelatedTier
        }
        focusStandards {
            ...CompleteCurriculumStandardContentTypeChooser
        }
        supportingStandards {
            ...CompleteCurriculumStandardContentTypeChooser
        }
        assessments {
            ...CompleteCurriculumLessonPageCurriculumAssessment
        }
        protocols {
            ...CompleteCurriculumLessonPageCurriculumProtocolPage
        }
        overviewVideo {
            ...CompleteVideoBlock
        }
        searchDescription
        seoOgImage {
            ...CompleteCustomImage
        }
        downloadables {
            ...CompleteDownloadables
        }
        moduleSummaryData
        previous {
            ...CompleteCurriculumLink
        }
        next {
            ...CompleteCurriculumLink
        }
    }
    ${CompleteCurriculumLessonAgendaTier}
    ${CompleteCurriculumMaterialTier}
    ${CompleteCurriculumLessonPlanTier}
    ${CompleteRelatedTier}
    ${CompleteCurriculumStandardContentTypeChooser}
    ${CompleteCurriculumLessonPageCurriculumAssessment}
    ${CompleteCurriculumLessonPageCurriculumProtocolPage}
    ${CompleteVideoBlock}
    ${CompleteCustomImage}
    ${CompleteDownloadables}
    ${CompleteCurriculumLink}
`;
