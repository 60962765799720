import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumPageGradeBlock on CurriculumPageGradeBlock {
        grade
        gradeTitle
        gradeDescription
        gradeTableSummary
    }
`;
