import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import CompleteRelatedListBlock from '@fragment/shared/CompleteRelatedListBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteRelatedBaseTier on RelatedBaseTierInterface {
        id
        blockStyle
        blockType
        header
        dek
        highlight
        backgroundImage {
            ...CompleteCustomImage
        }
        viewAllLink {
            ...CompleteLinkBlock
        }
        related {
            ...CompleteRelatedListBlock
        }
    }
    ${CompleteCustomImage}
    ${CompleteLinkBlock}
    ${CompleteRelatedListBlock}
`;
