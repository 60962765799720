import CompleteCurriculumStandardContentType from '@fragment/shared/CompleteCurriculumStandardContentType';

import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumStandardContentTypeChooser on CurriculumStandardContentTypeChooser {
        standardContentType {
            ...CompleteCurriculumStandardContentType
        }
    }
    ${CompleteCurriculumStandardContentType}
`;
