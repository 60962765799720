import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import CompleteCurriculumUnitFourTsTier from '@fragment/shared/CompleteCurriculumUnitFourTsTier';
import CompleteCurriculumRequiredTextChooserBlock from '@fragment/shared/CompleteCurriculumRequiredTextChooserBlock';
import CompleteCurriculumAssessmentPage from '@fragment/pages/CompleteCurriculumAssessmentPage';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';
import PagePreview from '@fragment/previews/PagePreview';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';
import CompleteCurriculumLink from '@fragment/shared/CompleteCurriculumLink';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumUnitPage on CurriculumUnitPage {
        id
        noIndex
        access
        parent {
            id
            title
            url
            urlPath
            pageType
            parent {
                id
                title
                url
                urlPath
                pageType
                parent {
                    id
                    title
                    url
                    urlPath
                    pageType
                }
            }
        }
        title
        formattedTitle
        subtitle
        componentType
        gradePage {
            id
            title
            url
        }
        moduleNumber
        unitNumber
        introduction
        overviewVideo {
            ...CompleteVideoBlock
        }
        theFourTs {
            ...CompleteCurriculumUnitFourTsTier
        }
        cssStandards
        habitsOfCharacter
        requiredTexts {
            ...CompleteCurriculumRequiredTextChooserBlock
        }
        additionalTextsCitation
        assessmentTextsCitation
        assessments {
            ...CompleteCurriculumAssessmentPage
        }
        related {
            ...CompleteRelatedTier
        }
        lessons {
            ...PagePreview
        }
        searchDescription
        seoOgImage {
            ...CompleteCustomImage
        }
        downloadables {
            ...CompleteDownloadables
        }
        moduleSummaryData
        previous {
            ...CompleteCurriculumLink
        }
        next {
            ...CompleteCurriculumLink
        }
    }
    ${CompleteVideoBlock}
    ${CompleteCurriculumUnitFourTsTier}
    ${CompleteCurriculumRequiredTextChooserBlock}
    ${CompleteCurriculumAssessmentPage}
    ${CompleteRelatedTier}
    ${PagePreview}
    ${CompleteCustomImage}
    ${CompleteDownloadables}
    ${CompleteCurriculumLink}
`;
