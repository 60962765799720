import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumRequiredTextObjectType on CurriculumRequiredTextObjectType {
        grade
        module
        externalId
        componentType
        title
        author
        textType
        publisher
        year
        physicalProductId
        digitalProductId
        lexile
        citation
        quantity
        notes
        media
        language
        abbreviatedTitle
        language
    }
`;
