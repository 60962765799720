import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteLinkSetTier on LinkSetTier {
        header
        linkToAll {
            ...CompleteLinkBlock
        }
        linkList {
            ...CompleteLinkBlock
        }
    }
    ${CompleteLinkBlock}
`;
