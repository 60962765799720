import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteLocation from '@fragment/shared/CompleteLocation';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteImageChooserBlock from '@fragment/shared/CompleteImageChooserBlock';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteSchool on School {
        id
        noIndex
        access
        name
        url
        logo {
            ...CompleteCustomImage
        }
        website
        type
        status {
            id
            name
        }
        gradeNames: grades
        location {
            ...CompleteLocation
        }
        hasProfilePage
        portfolioName
        portfolioWebsite
        portfolioThumbnailImage {
            ...CompleteCustomImage
        }
        profileContent
        relatedStudies {
            ...CompletePageChooserBlock
        }
        relatedResources {
            ...CompletePageChooserBlock
        }
        galleryImages {
            ...CompleteImageChooserBlock
        }
        seoOgImage {
            ...CompleteCustomImage
        }
        blogPosts {
            ...CompletePageChooserBlock
        }
    }
    ${CompleteCustomImage}
    ${CompleteLocation}
    ${CompletePageChooserBlock}
    ${CompleteImageChooserBlock}
`;
