import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteHeroVideoTier from '@fragment/shared/CompleteHeroVideoTier';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import PagePreview from '@fragment/previews/PagePreview';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';
import ProfessionalLearningPreview from '@fragment/previews/ProfessionalLearningPreview';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteCorePracticeDomain on CorePracticeDomain {
        id
        noIndex
        access
        __typename
        sequence
        title
        url
        urlPath
        descriptionEn
        descriptionEs
        hero {
            ...CompleteHeroTier
            ...CompleteHeroVideoTier
        }
        relatedResources {
            ...CompletePageChooserBlock
        }
        relatedVideos {
            ...CompletePageChooserBlock
        }
        corePractices {
            ...PagePreview
        }
        relatedBook {
            ...PagePreview
        }
        related {
            ...CompleteRelatedTier
        }
        blogPost: blogPosts {
            ...PagePreview
        }
        relatedProfessionalLearningCatalogEntries {
            ...ProfessionalLearningPreview
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteHeroTier}
    ${CompleteHeroVideoTier}
    ${CompletePageChooserBlock}
    ${PagePreview}
    ${CompleteRelatedTier}
    ${ProfessionalLearningPreview}
    ${CompleteCustomImage}
`;
