import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteSimpleTableBlock from '@fragment/shared/CompleteSimpleTableBlock';
import CompleteCurriculumVideoSectionTier from '@fragment/shared/CompleteCurriculumVideoSectionTier';
import CompleteRelatedListBlock from '@fragment/shared/CompleteRelatedListBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumLessonPlanTier on CurriculumLessonPlanTier {
        header
        duration
        content {
            ...CompleteRichTextContentBlock
            ...CompleteSimpleTableBlock
            ...CompleteCurriculumVideoSectionTier
            ...CompleteRelatedListBlock
        }
    }
    ${CompleteRichTextContentBlock}
    ${CompleteSimpleTableBlock}
    ${CompleteCurriculumVideoSectionTier}
    ${CompleteRelatedListBlock}
`;
