import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteSearchOptions from '@fragment/shared/CompleteSearchOptions';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumProtocolSearchIndex on CurriculumProtocolSearchIndex {
        noIndex
        access
        searchQuery
        searchQueryFragment
        searchQueryOptions {
            ...CompleteSearchOptions
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
    ${CompleteSearchOptions}
`;
