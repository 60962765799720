import PagePreview from '@fragment/previews/PagePreview';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompletePerson from '@fragment/pages/CompletePerson';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteCorePractice from '@fragment/pages/CompleteCorePractice';
import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteExternalArticleBlock from '@fragment/shared/CompleteExternalArticleBlock';
import CompleteWebsiteBlock from '@fragment/shared/CompleteWebsiteBlock';
import CompleteImageBlock from '@fragment/shared/CompleteImageBlock';
import CompleteQuoteBlock from '@fragment/shared/CompleteQuoteBlock';
import CompleteExcerptBlock from '@fragment/shared/CompleteExcerptBlock';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteBlogPost on BlogPost {
        id
        noIndex
        access
        title
        url
        subtitle
        firstPublishedAt
        lastPublishedAt
        publishedDateOverride
        previousPost {
            ...PagePreview
        }
        nextPost {
            ...PagePreview
        }
        thumbnailImage {
            ...CompleteCustomImage
        }
        categories {
            id
            name
        }
        author {
            ...CompletePerson
        }
        relatedResources {
            ...CompletePageChooserBlock
        }
        relatedPosts {
            ...CompletePageChooserBlock
        }
        corePractices {
            ...CompleteCorePractice
        }
        content {
            ...CompleteRichTextContentBlock
            ...CompleteExternalArticleBlock
            ...CompleteWebsiteBlock
            ...CompleteImageBlock
            ...CompleteQuoteBlock
            ...CompleteExcerptBlock
            ...CompleteVideoBlock
        }
        footerContent {
            ...DefaultTiers
        }
        School: relatedSchools {
            ...PagePreview
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${PagePreview}
    ${CompleteCustomImage}
    ${CompletePerson}
    ${CompletePageChooserBlock}
    ${CompleteCorePractice}
    ${CompleteRichTextContentBlock}
    ${CompleteExternalArticleBlock}
    ${CompleteWebsiteBlock}
    ${CompleteImageBlock}
    ${CompleteQuoteBlock}
    ${CompleteExcerptBlock}
    ${CompleteVideoBlock}
    ${DefaultTiers}
`;
