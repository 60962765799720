import CompleteNavigationItemBlock from '@fragment/shared/CompleteNavigationItemBlock';
import CompleteNavigationNestedSubMenuBlock from '@fragment/shared/CompleteNavigationNestedSubMenuBlock';
import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteMicrositeNavigationMenuBlock on MicrositeNavigationMenuBlock {
        backgroundColor
        navMenuItems {
            ...CompleteMicrositeNavigationSubmenuBlock
            ...CompleteLinkBlock
            ...CompleteNavigationNestedSubMenuBlock
        }
    }

    fragment CompleteMicrositeNavigationSubmenuBlock on MicrositeNavigationSubmenuBlock {
        submenuTitle
        navSubMenuItems {
            ...CompleteLinkBlock
        }
    }

    ${CompleteLinkBlock}
    ${CompleteNavigationItemBlock}
    ${CompleteNavigationNestedSubMenuBlock}
`;
