<template>
    <div class="informational-page" :style="navigablePageStyles">
        <Parse v-for="hero in page.hero" :data="hero" :key="hero.id" />

        <NavigablePageMenu v-if="navigableSections.length" :navigableSections="navigableSections" ref="menu" />

        <NavigablePageSections :sections="pageSections" ref="sections">
            <template #section="{ ...section }">
                <Parse :data="section" />
            </template>
        </NavigablePageSections>
    </div>
</template>
<script>
import Parse from '@parse';
import NavigablePageMenu from '@module/NavigablePageMenu';
import NavigablePageSections from '@module/NavigablePageSections';
import navigablePage from '@mixin/navigablePage';

/**
 * Renders an "Informational". Data is passed in under a "page" parameter
 */
export default {
    mixins: [
        navigablePage({
            sectionsPath: 'page.content',
            sectionIdProperty: 'subnavigationLabel',
            sectionLabelProperty: 'subnavigationLabel',
        }),
    ],
    props: {
        page: {
            type: Object,
            required: true,
        },
    },
    components: {
        Parse,
        NavigablePageMenu,
        NavigablePageSections,
    },
};
</script>
