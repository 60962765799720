import SingleTiers from '@fragment/shared/SingleTiers';
import MultiTiers from '@fragment/shared/MultiTiers';
import gql from 'graphql-tag';

export default gql`
    fragment DefaultTiers on StreamFieldInterface {
        ...SingleTiers
        ...MultiTiers
    }
    ${SingleTiers}
    ${MultiTiers}
`;
