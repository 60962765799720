import CompleteFeatureBlock from '@fragment/shared/CompleteFeatureBlock';
import CompleteCTABlock from '@fragment/shared/CompleteCTABlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompletePricingPlanBlock on PricingPlanBlock {
        featureBlockHeader
        color
        features {
            ...CompleteFeatureBlock
        }
        cta {
            ...CompleteCTABlock
        }
    }
    ${CompleteFeatureBlock}
    ${CompleteCTABlock}
`;
