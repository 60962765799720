import CompleteMultiContentTier from '@fragment/shared/CompleteMultiContentTier';
import CompleteTimelineTier from '@fragment/shared/CompleteTimelineTier';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteHistoryLanding on HistoryLanding {
        id
        noIndex
        access
        multicontent {
            ...CompleteMultiContentTier
        }
        timeline {
            ...CompleteTimelineTier
        }
        footerContent {
            ...DefaultTiers
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteMultiContentTier}
    ${CompleteTimelineTier}
    ${DefaultTiers}
    ${CompleteCustomImage}
`;
