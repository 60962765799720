import CompleteCurriculumRequiredText from '@fragment/shared/CompleteCurriculumRequiredText';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumRequiredTextChooserBlock on CurriculumRequiredTextChooserBlock {
        requiredText {
            ...CompleteCurriculumRequiredText
        }
    }
    ${CompleteCurriculumRequiredText}
`;
