import CompleteCurriculumAssessmentPage from '@fragment/pages/CompleteCurriculumAssessmentPage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumLessonPageCurriculumAssessment on CurriculumLessonPageCurriculumAssessment {
        assessment {
            ...CompleteCurriculumAssessmentPage
        }
    }
    ${CompleteCurriculumAssessmentPage}
`;
