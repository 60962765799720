import gql from 'graphql-tag';

export default gql`
    fragment CompleteSimpleTableBlock on SimpleTableBlock {
        id
        blockType
        footer
        table {
            heading
            rows
        }
        tableSummary
        tableCaption
        tableMinimumWidth
        columnWidths
    }
`;
