import { print } from 'graphql';

export default {
    methods: {
        async writeLastQuery(query, variables, fileName) {
            if (process.env.NODE_ENV === 'development') {
                const lastQuery = `${print(query)}\n${JSON.stringify({
                    ...variables,
                })}`;
                if (process.server) {
                    const fs = require('fs');
                    await fs.writeFile(`debug/${fileName}.gql`, lastQuery, { flag: 'w' }, (err) =>
                        err ? console.log(err) : null,
                    );
                } else {
                    // uncomment line below to log GQL query
                    // console.log(lastQuery);
                }
            }
        },
    },
};
