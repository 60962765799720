import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import PagePreview from '@fragment/previews/PagePreview';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteNewsEventsLanding on NewsEventsLanding {
        id
        noIndex
        access
        title
        showFeaturedPost
        showInTheNewsSection
        showPressReleaseSection
        featuredPage {
            ...CompletePageChooserBlock
        }
        footerContent {
            ...DefaultTiers
        }
        latestNews {
            ...PagePreview
        }
        latestPressReleases {
            ...PagePreview
        }
        awardsAndRecognition {
            ...PagePreview
        }
        seoOgImage {
            ...CompleteCustomImage
        }
        upcomingEvents {
            ...PagePreview
        }
        pastEvents {
            ...PagePreview
        }
        latestPost {
            ...PagePreview
        }
    }

    ${CompletePageChooserBlock}
    ${DefaultTiers}
    ${PagePreview}
    ${CompleteCustomImage}
`;
