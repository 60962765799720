import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteMicrositeFooterSocialsBlock from '@fragment/shared/CompleteMicrositeFooterSocialsBlock';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteMicrositeFooterLogoBlock on MicrositeFooterLogoBlock {
        logo {
            ...CompleteCustomImage
        }
        footerSocials {
            ...CompleteMicrositeFooterSocialsBlock
        }
    }

    ${CompleteCustomImage}
    ${CompleteMicrositeFooterSocialsBlock}
`;
