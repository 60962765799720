import CompleteCustomImageObjectType from '@fragment/shared/CompleteCustomImageObjectType';
import gql from 'graphql-tag';

export default gql`
    fragment CompletePLConferenceCourse on PLConferenceCourse {
        title
        courseDayTitle
        courseDayDate
        courseSessionTitle
        courseNumber
        description
        facilitators {
            photo {
                ...CompleteCustomImageObjectType
            }
            firstName
            lastName
            accolades
            bioSummary
        }
        schoolOrOrganization
        gradeNames: grades
        eligibility {
            name
        }
    }
    ${CompleteCustomImageObjectType}
`;
