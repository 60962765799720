import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteFloatingImageBlock from '@fragment/shared/CompleteFloatingImageBlock';
import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteTimelineTier on TimelineTier {
        blockType
        backgroundImage {
            ...CompleteCustomImage
        }
        blockStyle
        backgroundSplit
        blockTail
        floatingImage {
            ...CompleteFloatingImageBlock
        }
        years {
            ... on TimelineYearBlock {
                year
                backgroundColor
                milestones {
                    ... on TimelineMilestoneBlock {
                        id
                        title
                        description
                        link {
                            ...CompleteLinkBlock
                        }
                        relatedPages {
                            ...CompletePageChooserBlock
                        }
                        year
                    }
                }
            }
        }
    }
    ${CompleteCustomImage}
    ${CompleteFloatingImageBlock}
    ${CompleteLinkBlock}
    ${CompletePageChooserBlock}
`;
