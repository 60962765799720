import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import CompleteImageBlock from '@fragment/shared/CompleteImageBlock';
import CompleteOneColumnTier from '@fragment/shared/CompleteOneColumnTier';
import CompleteTwoColumnTier from '@fragment/shared/CompleteTwoColumnTier';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteAwardRecipient from '@fragment/shared/CompleteAwardRecipient';
import CompleteCustomForm from '@fragment/pages/CompleteCustomForm';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteImageChooserBlock from '@fragment/shared/CompleteImageChooserBlock';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteAward on Award {
        id
        noIndex
        access
        title
        year
        description
        submissionsOpen
        submissionsClose
        importantDates {
            ... on ImportantDateBlock {
                title
                date
            }
        }
        content {
            ...CompleteLinkBlock
            ...CompleteImageBlock
            ...CompleteOneColumnTier
            ...CompleteTwoColumnTier
        }
        thumbnailImage {
            ...CompleteCustomImage
        }
        hero {
            ...CompleteHeroTier
        }
        recipients {
            ...CompleteAwardRecipient
        }
        previousAwardRecipients {
            ...CompleteAwardRecipient
        }
        campaignForm {
            ...CompleteCustomForm
        }
        relatedContent {
            ...CompletePageChooserBlock
        }
        marketingEvents {
            ...CompletePageChooserBlock
        }
        galleryHeader
        galleryDescription
        galleryImages {
            ...CompleteImageChooserBlock
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteLinkBlock}
    ${CompleteImageBlock}
    ${CompleteOneColumnTier}
    ${CompleteTwoColumnTier}
    ${CompleteCustomImage}
    ${CompleteHeroTier}
    ${CompleteAwardRecipient}
    ${CompleteCustomForm}
    ${CompletePageChooserBlock}
    ${CompleteImageChooserBlock}
`;
