import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteFormBlock from '@fragment/shared/CompleteFormBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompletePressKitContact on PressKitContact {
        person {
            ...CompletePageChooserBlock
        }
        phone
        email
        form {
            ...CompleteFormBlock
        }
    }
    ${CompletePageChooserBlock}
    ${CompleteFormBlock}
`;
