import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import CompleteCurriculumAssessmentPage from '@fragment/pages/CompleteCurriculumAssessmentPage';
import PagePreview from '@fragment/previews/PagePreview';
import CompleteCurriculumPerformanceTask from '@fragment/shared/CompleteCurriculumPerformanceTask';
import CompleteCurriculumSimpleTableTier from '@fragment/shared/CompleteCurriculumSimpleTableTier';
import CompleteCurriculumContentSectionTier from '@fragment/shared/CompleteCurriculumContentSectionTier';
import CompleteCurriculumContentTableSectionTier from '@fragment/shared/CompleteCurriculumContentTableSectionTier';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';
import CompleteCurriculumLink from '@fragment/shared/CompleteCurriculumLink';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumModulePage on CurriculumModulePage {
        id
        noIndex
        access
        parent {
            id
            parent {
                id
                title
                url
                urlPath
                pageType
            }
        }
        title
        componentType
        gradePage {
            id
            title
            url
        }
        moduleNumber
        focus
        introduction
        overviewVideo {
            ...CompleteVideoBlock
        }
        assessments {
            ...CompleteCurriculumAssessmentPage
        }
        units {
            ...PagePreview
        }
        performanceTasks {
            ...CompleteCurriculumPerformanceTask
        }
        notesFromTheDesigner
        guidingQuestionsAndBigIdeas
        contentConnections
        independentResearchReading
        technologyAndMultimedia {
            ...CompleteCurriculumSimpleTableTier
        }
        optionalExpertsFieldworkServiceAndExtensions
        extras {
            ...CompleteCurriculumContentSectionTier
            ...CompleteCurriculumContentTableSectionTier
        }
        related {
            ...CompleteRelatedTier
        }
        searchDescription
        seoOgImage {
            ...CompleteCustomImage
        }
        downloadables {
            ...CompleteDownloadables
        }
        moduleSummaryData
        previous {
            ...CompleteCurriculumLink
        }
        next {
            ...CompleteCurriculumLink
        }
    }
    ${CompleteVideoBlock}
    ${CompleteCurriculumAssessmentPage}
    ${PagePreview}
    ${CompleteCurriculumPerformanceTask}
    ${CompleteCurriculumSimpleTableTier}
    ${CompleteCurriculumContentSectionTier}
    ${CompleteCurriculumContentTableSectionTier}
    ${CompleteRelatedTier}
    ${CompleteCustomImage}
    ${CompleteDownloadables}
    ${CompleteCurriculumLink}
`;
