import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteFloatingImageBlock from '@fragment/shared/CompleteFloatingImageBlock';
import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteOneColumnBaseTier from '@fragment/shared/CompleteOneColumnBaseTier';
import CompleteTwoColumnBaseTier from '@fragment/shared/CompleteTwoColumnBaseTier';
import CompleteContentCardsBaseTier from '@fragment/shared/CompleteContentCardsBaseTier';
import CompleteRelatedBaseTier from '@fragment/shared/CompleteRelatedBaseTier';
import CompleteColorBlocksTier from '@fragment/shared/CompleteColorBlocksTier';
import CompleteFormTier from '@fragment/shared/CompleteFormTier';
import CompleteNumberTileTier from '@fragment/shared/CompleteNumberTileTier';
import CompleteTileTier from '@fragment/shared/CompleteTileTier';
import CompleteMediaTier from '@fragment/shared/CompleteMediaTier';
import CompleteFeaturedResourceTier from '@fragment/shared/CompleteFeaturedResourceTier';
import CompleteSectionBaseTier from '@fragment/shared/CompleteSectionBaseTier';
import CompleteGalleryTier from '@fragment/shared/CompleteGalleryTier';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteMultiContentTier on MultiContentTier {
        id
        blockTail
        blockStyle
        blockType
        backgroundImage {
            ...CompleteCustomImage
        }
        floatingImage {
            ...CompleteFloatingImageBlock
        }
        multicontent {
            ...CompleteHeroTier
            ...CompleteOneColumnBaseTier
            ...CompleteTwoColumnBaseTier
            ...CompleteContentCardsBaseTier
            ...CompleteRelatedBaseTier
            ...CompleteColorBlocksTier
            ...CompleteFormTier
            ...CompleteNumberTileTier
            ...CompleteTileTier
            ...CompleteMediaTier
            ...CompleteFeaturedResourceTier
            ...CompleteSectionBaseTier
            ...CompleteGalleryTier
        }
    }
    ${CompleteCustomImage}
    ${CompleteFloatingImageBlock}
    ${CompleteHeroTier}
    ${CompleteOneColumnBaseTier}
    ${CompleteTwoColumnBaseTier}
    ${CompleteContentCardsBaseTier}
    ${CompleteRelatedBaseTier}
    ${CompleteColorBlocksTier}
    ${CompleteFormTier}
    ${CompleteNumberTileTier}
    ${CompleteTileTier}
    ${CompleteMediaTier}
    ${CompleteFeaturedResourceTier}
    ${CompleteSectionBaseTier}
    ${CompleteGalleryTier}
`;
