import CompleteSectionBaseTier from '@fragment/shared/CompleteSectionBaseTier';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteSectionWithSubsectionTier on SectionWithSubsectionTier {
        id
        blockType
        title
        navigationLabel
        subsections {
            ...CompleteSectionBaseTier
        }
    }
    ${CompleteSectionBaseTier}
`;
