import gql from 'graphql-tag'

export default gql`
    fragment CompleteQuoteBlock on QuoteBlock {
        id
        blockType
        quote
        attribution
    }
`;
