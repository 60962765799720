import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumComponentBlock on CurriculumComponentBlock {
        color
        text
        link {
            ...CompleteLinkBlock
        }
        tooltip
    }
    ${CompleteLinkBlock}
`;
