import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteImageBlock from '@fragment/shared/CompleteImageBlock';
import CompleteVideoBlock from '@fragment/shared/CompleteVideoBlock';
import CompleteTwoColumnTier from '@fragment/shared/CompleteTwoColumnTier';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import PagePreview from '@fragment/previews/PagePreview';
import gql from 'graphql-tag';

export default gql`
    fragment CompletePerson on Person {
        id
        noIndex
        access
        url
        firstName
        lastName
        photo {
            ...CompleteCustomImage
        }
        types
        hasBioPage
        showInquireButton
        bioContent {
            ...CompleteRichTextContentBlock
            ...CompleteImageBlock
            ...CompleteVideoBlock
        }
        quote {
            ...CompleteTwoColumnTier
        }
        blogPosts {
            ...CompletePageChooserBlock
        }
        awards {
            ...PagePreview
        }
        marketingEvents {
            ...CompletePageChooserBlock
        }
        resources {
            ...CompletePageChooserBlock
        }
        titles
        department
        company
        companyTitle
        boardTitle
        schoolName
        grade
        organizations
        spokespersonTopics {
            name
        }
        contactPageUrl
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
    ${CompleteRichTextContentBlock}
    ${CompleteImageBlock}
    ${CompleteVideoBlock}
    ${CompleteTwoColumnTier}
    ${CompletePageChooserBlock}
    ${PagePreview}
`;
