import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteRoutinePage on RoutinePage {
        title
        url
        component
        edition
        language
        docType
        breadcrumb
        sections
        types {
            id
            name
        }
        categories {
            id
            name
        }
        downloadables {
            ...CompleteDownloadables
        }
    }

    ${CompleteDownloadables}
`;
