import CompleteCurriculumProtocolPage from '@fragment/pages/CompleteCurriculumProtocolPage';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumLessonPageCurriculumProtocolPage on CurriculumLessonPageCurriculumProtocolPage {
        protocol {
            ...CompleteCurriculumProtocolPage
        }
    }
    ${CompleteCurriculumProtocolPage}
`;
