<template>
    <div class="not-found-page column-layout">
        <div class="not-found-page__column column-layout__column column-layout-left">
            <Header2>404: Page Not Found</Header2>
        </div>
        <div class="not-found-page__column column-layout__column column-layout-right">
            <img class="not-found-page__img" src="~/assets/images/404.jpg" alt="Student" />
        </div>
    </div>
</template>
<script>
import Header2 from '@typography/Header2';

export default {
    components: {
        Header2,
    },
};
</script>
