import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteMarketingEventSpeakerBlock on MarketingEventSpeakerBlock {
        person {
            ...CompletePageChooserBlock
        }
        description
    }
    ${CompletePageChooserBlock}
`;
