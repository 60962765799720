import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompleteTierGroup from '@fragment/shared/CompleteTierGroup';
import CompleteLinkSetTier from '@fragment/shared/CompleteLinkSetTier';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteModelOfExcellencePage on ModelOfExcellencePage {
        id
        title
        hero {
            ...CompleteHeroTier
        }
        hasSubnavigation
        content {
            ...DefaultTiers
            ...CompleteTierGroup
            ...CompleteLinkSetTier
            ...CompleteFormTier
        }
        searchQuery
        searchQueryFragment
        searchQueryOptions {
            __typename
            name
            label
            options {
                __typename
                id: filterId
                name: filterName
                field: filterField
            }
        }
    }
    ${CompleteHeroTier}
    ${DefaultTiers}
    ${CompleteTierGroup}
    ${CompleteLinkSetTier}
`;
