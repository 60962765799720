import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompletePartnerJobOpeningLanding on PartnerJobOpeningLanding {
        id
        noIndex
        access
        title
        header
        searchQuery
        searchQueryFragment
        searchQueryOptions {
            __typename
            name
            label
            options {
                __typename
                id: filterId
                name: filterName
                field: filterField
            }
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
`;
