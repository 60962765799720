import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteNewsletterForm from '@fragment/shared/CompleteNewsletterForm';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteContactUsPage on ContactUsPage {
        id
        noIndex
        access
        hero {
            ...CompleteHeroTier
        }
        content {
            ...DefaultTiers
        }
        newsletterForm {
            ...CompleteNewsletterForm
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }

    ${CompleteHeroTier}
    ${DefaultTiers}
    ${CompleteNewsletterForm}
    ${CompleteCustomImage}
`;
