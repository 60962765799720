import CompleteCurriculumComponentsBlock from '@fragment/shared/CompleteCurriculumComponentsBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumComponentsCategoryBlock on CurriculumComponentsCategoryBlock {
        categoryLabel
        blocks {
            ...CompleteCurriculumComponentsBlock
        }
    }
    ${CompleteCurriculumComponentsBlock}
`;
