import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import CompleteImageBlock from '@fragment/shared/CompleteImageBlock';
import CompleteDocumentBlock from '@fragment/shared/CompleteDocumentBlock';
import PagePreview from '@fragment/previews/PagePreview';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteAnnualReport on AnnualReport {
        id
        noIndex
        access
        title
        subtitle
        # year
        thumbnailImage {
            ...CompleteCustomImage
        }
        hero {
            ...CompleteHeroTier
        }
        content {
            ...CompleteRichTextContentBlock
            ...CompleteLinkBlock
            ...CompleteImageBlock
        }
        externalLink {
            ... on LinkBlockCustom {
                blockType
                linkTo
                newWindow
                customUrl
                text
            }
        }
        documents {
            ...CompleteDocumentBlock
        }
        recentAnnualReports {
            ...PagePreview
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
    ${CompleteHeroTier}
    ${CompleteRichTextContentBlock}
    ${CompleteLinkBlock}
    ${CompleteImageBlock}
    ${CompleteDocumentBlock}
    ${PagePreview}
`;
