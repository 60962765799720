import CompleteCurriculumStandardContentTypeChooser from '@fragment/shared/CompleteCurriculumStandardContentTypeChooser';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumUnitFourTsTier on CurriculumUnitFourTsTier {
        blockType
        header
        topic
        task
        targets {
            ...CompleteCurriculumStandardContentTypeChooser
        }
    }
    ${CompleteCurriculumStandardContentTypeChooser}
`;
