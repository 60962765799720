import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteResourceSearchIndex on ResourceSearchIndex {
        noIndex
        access
        searchQuery
        searchQueryFragment
        searchQueryOptions {
            __typename
            name
            label
            showAllToggle
            options {
                __typename
                id: filterId
                name: filterName
                field: filterField
            }
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
`;
