import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteCustomImageObjectType from '@fragment/shared/CompleteCustomImageObjectType';
import CompleteMarketingPhysicalLocationBlock from '@fragment/shared/CompleteMarketingPhysicalLocationBlock';
import CompleteMarketingEventVirtualLocationBlock from '@fragment/shared/CompleteMarketingEventVirtualLocationBlock';
import CompleteRegistrationLinkBlock from '@fragment/shared/CompleteRegistrationLinkBlock';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompletePLConferenceCourse from '@fragment/shared/CompletePLConferenceCourse';
import gql from 'graphql-tag'

export default gql`
    fragment CompletePLConference on PLConference {
        url
        title
        description
        hero {
            ...CompleteHeroTier
        }
        speakers {
            photo {
                ...CompleteCustomImageObjectType
            }
            firstName
            lastName
            accolades
            bioSummary
        }
        topics {
            name
        }
        format {
            name
        }
        eligibility {
            id
            name
        }
        eventLocation {
            ...CompleteMarketingPhysicalLocationBlock
            ...CompleteMarketingEventVirtualLocationBlock
        }
        timezone
        startDate
        endDate
        memberPrice
        nonmemberPrice
        registrationLink {
            ...CompleteRegistrationLinkBlock
        }
        content {
            ...DefaultTiers
        }
        days {
            title
            date
            sessions {
                title
                startTime
                endTime
                courses {
                    ...CompletePLConferenceCourse
                }
            }
        }
        searchQuery
        searchQueryFragment
        searchQueryOptions {
            __typename
            name
            label
            options {
                __typename
                id: filterId
                name: filterName
                field: filterField
            }
        }
    }
    ${CompleteHeroTier}
    ${CompleteCustomImageObjectType}
    ${CompleteMarketingPhysicalLocationBlock}
    ${CompleteMarketingEventVirtualLocationBlock}
    ${CompleteRegistrationLinkBlock}
    ${DefaultTiers}
    ${CompletePLConferenceCourse}
`;
