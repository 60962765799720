import gql from 'graphql-tag';

export default gql`
    fragment CompleteUserRegistrationForm on UserRegistrationForm {
        id
        title
        formUrl
        isModal
    }
`;
