import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteTaskCardSearchIndex on TaskCardSearchIndex {
        noIndex
        access
        searchQuery
        searchQueryFragment
        searchQueryOptions {
            __typename
            name
            label
            options {
                __typename
                id: filterId
                name: filterName
                field: filterField
            }
        }
        seoOgImage {
            ...CompleteCustomImage
        }
        downloadables {
            ...CompleteDownloadables
        }
    }

    ${CompleteCustomImage}
    ${CompleteDownloadables}
`;
