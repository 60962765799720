import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteCardBlock from '@fragment/shared/CompleteCardBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteContentCardsBaseTier on ContentCardsBaseTier {
        id
        blockStyle
        blockType
        backgroundImage {
            ...CompleteCustomImage
        }
        header
        dek
        cornerCutout
        contentCards {
            ...CompleteCardBlock
        }
        highlight
    }
    ${CompleteCustomImage}
    ${CompleteCardBlock}
`;
