import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompleteUserRegistrationForm from '@fragment/shared/CompleteUserRegistrationForm';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteUserRegistrationPage on UserRegistrationPage {
        id
        noIndex
        access
        title
        content {
            ...DefaultTiers
        }
        userRegistrationForm {
            ...CompleteUserRegistrationForm
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${DefaultTiers}
    ${CompleteUserRegistrationForm}
    ${CompleteCustomImage}
`;
