import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import DefaultBlocks from '@fragment/shared/DefaultBlocks';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import CompleteDocumentBlock from '@fragment/shared/CompleteDocumentBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteStudy on Study {
        id
        noIndex
        access
        title
        urlPath
        subtitle
        type
        description
        location {
            id
        }
        thumbnailImage {
            ...CompleteCustomImage
        }
        hero {
            ...CompleteHeroTier
        }
        content {
            ...DefaultBlocks
        }
        schools {
            ...CompletePageChooserBlock
        }
        externalLink {
            ...CompleteLinkBlock
        }
        document {
            ...CompleteDocumentBlock
        }
        live
        firstPublishedAt
        latestRevisionCreatedAt
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
    ${CompleteHeroTier}
    ${DefaultBlocks}
    ${CompletePageChooserBlock}
    ${CompleteLinkBlock}
    ${CompleteDocumentBlock}
`;
