import CompleteCurriculumTextBlock from '@fragment/shared/CompleteCurriculumTextBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumArrowTier on CurriculumArrowTier {
        headline
        highlight
        description
        blockStyle
        textBlock {
            ...CompleteCurriculumTextBlock
        }
    }
    ${CompleteCurriculumTextBlock}
`;
