import CompleteCurriculumStandardContentTypeChooser from '@fragment/shared/CompleteCurriculumStandardContentTypeChooser';
import CompleteCurriculumMaterialTier from '@fragment/shared/CompleteCurriculumMaterialTier';
import PagePreview from '@fragment/previews/PagePreview';
import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';

import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumAssessmentPage on CurriculumAssessmentPage {
        id
        title
        componentType
        grade
        moduleNumber
        unitNumber
        componentType
        focusStandards {
            ...CompleteCurriculumStandardContentTypeChooser
        }
        introduction
        materials {
            ...CompleteCurriculumMaterialTier
        }
        lessons {
            page {
                ...PagePreview
            }
        }
        downloadables {
            ...CompleteDownloadables
        }
    }
    ${CompleteCurriculumStandardContentTypeChooser}
    ${CompleteCurriculumMaterialTier}
    ${PagePreview}
    ${CompleteDownloadables}
`;
