import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteMicrositeFooterCTABlock on MicrositeFooterCTABlock {
        buttonStyle
        url {
            ...CompleteLinkBlock
        }
    }

    ${CompleteLinkBlock}
`;
