import gql from 'graphql-tag';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';

export default gql`
    fragment CompletePeopleBlock on PeopleBlock {
        header
        dek
        people {
            ...CompletePageChooserBlock
        }
    }

    ${CompletePageChooserBlock}
`;
