import gql from 'graphql-tag';

export default gql`
    fragment CompleteNewsletterForm on NewsletterForm {
        id
        title
        formUrl
        isModal
    }
`;
