import CompleteHeroTier from '@fragment/shared/CompleteHeroTier';
import CompleteMicrositeBannerBlock from '@fragment/shared/CompleteMicrositeBannerBlock';
import CompleteMicrositeNavigationMenuBlock from '@fragment/shared/CompleteMicrositeNavigationMenuBlock';
import CompleteMicrositeFooterBlock from '@fragment/shared/CompleteMicrositeFooterBlock';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompletePeopleBlock from '@fragment/shared/CompletePeopleBlock';
import CompleteTierGroup from '@fragment/shared/CompleteTierGroup';
import CompleteCurriculumTwoColumnAndImageTier from '@fragment/shared/CompleteCurriculumTwoColumnAndImageTier';
import CompleteCurriculumArrowTier from '@fragment/shared/CompleteCurriculumArrowTier';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteTableBlock from '@fragment/shared/CompleteTableBlock';
import CompleteAccordionBlock from '@fragment/shared/CompleteAccordionBlock';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteMicrositePage on MicrositePage {
        id
        title
        hero {
            ...CompleteHeroTier
        }
        navigation {
            ...CompleteMicrositeNavigationMenuBlock
        }

        banner {
            ...CompleteMicrositeBannerBlock
        }
        content {
            ...DefaultTiers
            ...CompleteTierGroup
            ...CompleteCurriculumTwoColumnAndImageTier
            ...CompleteCurriculumArrowTier
            ...CompleteTableBlock
            ...CompleteAccordionBlock
            ...CompletePeopleBlock
        }
        footer {
            ...CompleteMicrositeFooterBlock
        }
    }
    ${CompleteHeroTier}
    ${CompleteMicrositeBannerBlock}
    ${CompleteMicrositeNavigationMenuBlock}
    ${CompleteMicrositeFooterBlock}
    ${DefaultTiers}
    ${CompleteTierGroup}
    ${CompleteCurriculumTwoColumnAndImageTier}
    ${CompleteCurriculumArrowTier}
    ${CompleteCustomImage}
    ${CompleteTableBlock}
    ${CompleteAccordionBlock}
    ${CompletePeopleBlock}
`;
