import CompleteMicrositeFooterLogoBlock from '@fragment/shared/CompleteMicrositeFooterLogoBlock';
import CompleteMicrositeFooterColumnBlock from '@fragment/shared/CompleteMicrositeFooterColumnBlock';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteMicrositeFooterBlock on MicrositeFooterBlock {
        backgroundColor
        columns {
            ...CompleteMicrositeFooterLogoBlock
            ...CompleteMicrositeFooterColumnBlock
        }
    }

    ${CompleteMicrositeFooterLogoBlock}
    ${CompleteMicrositeFooterColumnBlock}
`;
