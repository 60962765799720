import CompleteCurriculumStandardObjectType from '@fragment/shared/CompleteCurriculumStandardObjectType';
import CompleteDocumentBlock from '@fragment/shared/CompleteDocumentBlock';
import CompleteCurriculumPageGradeBlock from '@fragment/shared/CompleteCurriculumPageGradeBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumPlanningLandingStandardPage on CurriculumPlanningLandingStandardPage {
        id
        title
        allStandards {
            ...CompleteCurriculumStandardObjectType
        }
        documentList {
            ...CompleteDocumentBlock
        }
        pageGrades {
            ...CompleteCurriculumPageGradeBlock
        }
    }
    ${CompleteCurriculumStandardObjectType}
    ${CompleteDocumentBlock}
    ${CompleteCurriculumPageGradeBlock}
`;
