import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumRequiredText on CurriculumRequiredTextContentType {
        componentType
        title
        author
        textType
        publisher
        year
        physicalProductId
        digitalProductId
        lexile
        citation
        quantity
        notes
        media
        language
        abbreviatedTitle
        language
    }
`;
