import CompleteTableBlock from '@fragment/shared/CompleteTableBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumContentTableSectionTier on CurriculumContentTableSectionTier {
        blockType
        header
        content
        table {
            ...CompleteTableBlock
        }
    }
    ${CompleteTableBlock}
`;
