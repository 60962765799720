import CompleteSocialMediaTier from '@fragment/shared/CompleteSocialMediaTier';
import CompletePressKit from '@fragment/shared/CompletePressKit';
import CompleteCustomForm from '@fragment/pages/CompleteCustomForm';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import DefaultTiers from '@fragment/shared/DefaultTiers';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompletePeopleBlock from '@fragment/shared/CompletePeopleBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteMediaKit on MediaKit {
        noIndex
        access
        description
        socialMedia {
            ...CompleteSocialMediaTier
        }
        mediaKit {
            ...CompletePressKit
        }
        pressListForm {
            ...CompleteCustomForm
        }
        spokespeople {
            ...CompletePeopleBlock
        }
        inTheNews {
            ...CompletePageChooserBlock
        }
        pressReleases {
            ...CompletePageChooserBlock
        }
        footerContent {
            ...DefaultTiers
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteSocialMediaTier}
    ${CompletePressKit}
    ${CompleteCustomForm}
    ${CompletePageChooserBlock}
    ${DefaultTiers}
    ${CompleteCustomImage}
    ${CompletePeopleBlock}
`;
