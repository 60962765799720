import PagePreview from '@fragment/previews/PagePreview';
import CompleteCurriculumMaterialTier from '@fragment/shared/CompleteCurriculumMaterialTier';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumPerformanceTask on CurriculumPerformanceTask {
        title
        componentType
        grade
        module {
            ...PagePreview
        }
        edition {
            ...PagePreview
        }
        taskSummary
        taskFormat
        standardsAddressedThroughThisTask
        taskDescription
        keyCriteriaForSuccess
        optionsForStudents
        optionsForTeachers
        materials {
            ...CompleteCurriculumMaterialTier
        }
    }
    ${PagePreview}
    ${CompleteCurriculumMaterialTier}
`;
