import CompleteRichTextContentBlock from '@fragment/shared/CompleteRichTextContentBlock';
import CompleteGalleryTier from '@fragment/shared/CompleteGalleryTier';
import CompleteRelatedListBlock from '@fragment/shared/CompleteRelatedListBlock';
import CompletePLEvent from '@fragment/shared/CompletePLEvent';
import CompletePLConference from '@fragment/pages/CompletePLConference';
import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompletePLCatalogEntry on PLCatalogEntry {
        id
        noIndex
        access
        title
        url
        type
        description
        content {
            ...CompleteRichTextContentBlock
            ...CompleteGalleryTier
            ...CompleteRelatedListBlock
        }
        upcomingPlEvents {
            ...CompletePLEvent
        }
        pastPlEvents {
            ...CompletePLEvent
        }
        upcomingPlConferences {
            ...CompletePLConference
        }
        pastPlConferences {
            ...CompletePLConference
        }
        upcomingPlConferencesCount
        corePracticeDomains {
            id
            title
        }
        topics {
            id
            name
        }
        eligibility {
            id
            name
        }
        audiences {
            id
            name
        }
        gradeNames: grades
        upcomingPlEventsCount
        memberPriceLow
        memberPriceHigh
        nonmemberPriceLow
        nonmemberPriceHigh
        relatedResources {
            ...CompletePageChooserBlock
        }
        seoOgImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteRichTextContentBlock}
    ${CompleteGalleryTier}
    ${CompleteRelatedListBlock}
    ${CompletePLEvent}
    ${CompletePLConference}
    ${CompletePageChooserBlock}
    ${CompleteCustomImage}
`;
